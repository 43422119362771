.ext-header-row {
    th {
        position: sticky;
        top: 43px;
        min-width: 150px;
    }
}

.status-label-bottom {
    vertical-align: bottom !important;
}
