.table-fields-setting {
    overflow: auto;

    &_name {
        width: 100% !important;
        white-space: nowrap;
    }

    .table-loader {
        margin-top: 95px !important;
        height: calc(100vh - 300px) !important;
    }
}

.cell-field-name {
    font-weight: 500;
    color: #4d4d4d;
    display: inline;
    margin-right: 5px;
}

.cell-field-name-edit {
    margin-top: -4px;
    margin-bottom: -4px;
}

.cell-number-field {
    max-width: 60px;
}

.field-settings-table {
    border: 1px solid #ddd;
    height: calc(100vh - 160px) !important;
}

.field-settings-table_fixed {
    position: sticky;
    left: 0;
    z-index: 9;

    &_header {
        z-index: 10!important;
    }
}

.dictionary-settings-column {
    width: 125px;
}


