.search-input {
    min-width: 200px;
    width: 30%;
    /* margin-right: 15px;
     width: 30%;
     min-width: 200px;

     input {
         height: 36px;
     }*/
}
